//
// locale_en.js
// automatically generated by generateLocalePacks.js
//
import messages from '../../app/javascript/mastodon/locales/en.json';
import localeData from "react-intl/locale-data/en.js";
import { setLocale } from '../../app/javascript/mastodon/locales';
import dateFnsLocaleDate from "date-fns/locale/en-US/index.js";
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import setDefaultOptions from 'date-fns/setDefaultOptions';
setDefaultOptions({ locale: dateFnsLocaleDate });
registerLocale('en', dateFnsLocaleDate);
setDefaultLocale('en');
setLocale({messages, localeData});
